<template>
  <div class="auth-btn-component">
    <span>
      <i class="el-icon-warning-outline text-warning icon"></i>
      企业实名认证待人工审核:
      <span v-loading="loading">{{ data }}</span>
      <span class="text-primary btn" @click="showDialog">去处理</span></span
    >
    <AuthDialog
      v-p="['externaladmin:user:list:manualAuditList']"
      ref="form"
      :dialogVisible.sync="dialogVisible"
      :sup_this="sup_this"
      :is-add="true"
      title="人工审核认证"
    />
  </div>
</template>

<script>
import AuthDialog from './module/authDialog'
import { mapGetters } from 'vuex'
import { commonEheaderMixin } from '@/mixins'
import {
  getUnApproveTotal //获取待审核数
} from '@/api/auth/realNameAuthApi'

export default {
  mixins: [commonEheaderMixin],

  components: { AuthDialog },

  data() {
    return {
      loading: true,
      data: 0
    }
  },

  computed: {
    ...mapGetters({
      userId: 'id'
    })
  },

  created() {
    this.getUnApproveTotal()
  },

  methods: {
    /**
     * @description: 获取待审核数
     * @param {*}
     * @return {*}
     */
    async getUnApproveTotal() {
      this.loading = true
      try {
        const { detail, code } = await getUnApproveTotal()
        if ($SUC({ code })) {
          this.data = detail
        }
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.auth-btn-component {
  font-size: 14px;
  margin-bottom: 15px;
  .icon {
    position: relative;
    top: 2px;
    margin-right: 5px;
    font-weight: 500;
    font-size: 18px;
  }
  .btn {
    margin-left: 10px;
    cursor: pointer;
  }
}
</style>
